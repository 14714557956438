const routes = [
    {
      index: true,
      label: "Home",
      path: '/home',
    },
    {
      label: 'Resume',
      path: '/resume',
    },
    // {
    //   label: 'Projects',
    //   path: '/projects',
    // },
    // {
    //   label: 'Education',
    //   path: '/education',
    // },
    // {
    //   label: 'Contact',
    //   path: '/contact',
    // },
  ];
  
  export default routes;